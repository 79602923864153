<template>
  <div>
    <v-expansion-panels mb="3">
      <v-expansion-panel v-for="reference in this.referenceList" :key="reference.id">
        <v-expansion-panel-header :color="getColor(reference)">
          <v-row>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Номер заказа</p>
                {{ reference.orderNumber }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">ФИО</p>
                {{ reference.fullName }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Почта</p>
                {{ reference.mail }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Телефон</p>
                {{ reference.phone }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Вид справки</p>
                {{ getReferenceTypeName(reference.referenceType) }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Заказано</p>
                {{ formatDate(reference.createdAt) }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Статус</p>
                {{ getStatus(reference) }}
              </div>
              <div v-if="isOrderTaken(reference) !== false">
                <p style="color: #e21a1a" class="pt-3">Забронирована оператором: {{ isOrderTaken(reference) }}</p>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content :color="getColor(reference)">
          <div>Заказ № {{ reference.orderNumber }}</div>
          <v-card-actions>
            <div><AdminListItem :order="reference" :queryParams="queryParams" /></div>
            <div><ActionsDialog :order="reference" /></div>
            <!-- <v-btn :to="{ path: `/orders/${reference.id}` }" target="_blank" depressed color="#e21a1a" class="white--text mr-2 text-RussianRail"
              >Открыть в новой вкладке</v-btn
            > -->
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import ViewHelper from '@/helpers/view.helper';
import AdminListItem from '@/components/admin/AdminListItem';
import ActionsDialog from '@/components/admin/ActionsDialog';

import moment from 'moment';
export default {
  props: {
    referenceList: {
      type: Array,
      required: true
    },
    queryParams: {
      required: true
    }
  },
  components: {
    AdminListItem,
    ActionsDialog
  },
  computed: {
    ...mapGetters('admin', ['orders'])
  },
  methods: {
    getSeatName: ReferenceHelper.getSeatName,
    getCarName: ReferenceHelper.getCarName,
    getTariffName: ReferenceHelper.getTariffName,
    getStationName: ReferenceHelper.getStationName,
    getOrderStatus: ReferenceHelper.getOrderStatus,
    formatDate: ViewHelper.formatDate,
    formatDateOnly: ViewHelper.formatDateOnly,
    getReferenceTypeName: ReferenceHelper.getReferenceTypeName,
    getStatus(reference) {
      return ReferenceHelper.getOrderStatus(reference.currentState);
    },
    getColor(item) {
      let color = '';
      if (item.currentState === 6) color = '#00FF7F20';
      else if (this.isOrderTaken(item) === false && item.currentState === 4) color = '#FFFF0020';
      else if (this.isOrderTaken(item).length > 0 && item.currentState === 4) color = '#1E90FF20';
      else if (item.currentState === 100) color = '#F1525220';
      return color;
    },
    isOrderTaken(order) {
      if (!order || !order.reservedDate) {
        return false;
      }
      if (moment(order.reservedDate).isBefore(moment().subtract(10, 'm'))) {
        return false;
      }
      if (!order.reservedUser.fio || order.reservedUser.fio.length <= 0) {
        return '';
      }
      return order.reservedUser.fio;
    }
  }
};
</script>
<style scoped>
.ReferenceOperatorListTableHeader {
  /* padding: 12px 24px 12px 12px; */
  font-weight: bold;
}
</style>
