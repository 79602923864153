<template>
  <nav>
    <v-app-bar color="white" flat app>
      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text"></v-app-bar-nav-icon>
      <img class="mr-3" src="./assets/Лого Вокзалы России 1.svg" height="50px" />
      <v-spacer></v-spacer>
      <img class="mr-3" src="./assets/RZD.svg" />
      <v-menu offset-y bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="grey--text" text v-bind="attrs" v-on="on">
            <v-icon left>mdi-account</v-icon>
            <span class="font-weight-light">{{ user.username }}</span>
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-divider class="mx-2"></v-divider>
          <v-list-item @click="logoutUser">
            <v-list-item-content>
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list dense nav class="d-flex flex-column">
        <template v-for="(group, index) in userLinkGroups">
          <v-divider v-if="index > 0" :key="index" />
          <v-list-item v-for="link in group" :key="link.label" router :to="link.url">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ link.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      drawer: false,
      defaultGroup: [
        {
          label: 'Справки',
          icon: 'mdi-folder-outline',
          url: '/reference'
        }
      ],
      adminGroup: [
        { label: 'Пользователи', icon: 'mdi-account-outline', url: '/users' },
        // { label: 'Станции', icon: 'mdi-account-outline', url: '/station' },
        { label: 'Администратор', icon: 'mdi-account-outline', url: '/admin' }
      ],
      operatorGroup: [
        {
          label: 'Оператор',
          icon: 'mdi-folder-outline',
          url: '/operator'
        }
      ]
    };
  },
  computed: {
    userLinkGroups() {
      const userGroups = [];
      if (this.checkPermission('admin')) {
        userGroups.push(this.adminGroup);
      }
      userGroups.push(this.defaultGroup);
      if (this.checkPermission('operator')) {
        userGroups.push(this.operatorGroup);
      }
      return userGroups;
    },
    ...mapGetters('auth', ['user'])
  },
  methods: {
    checkPermission(permissionKey) {
      if (this.user && this.user.permission && this.user.permission[permissionKey] && this.user.permission[permissionKey] > 0) {
        return true;
      }
      return false;
    },
    logoutUser() {
      this.logout();
      this.$router.push('/login');
    },
    ...mapActions({ logout: 'auth/logout' })
  }
};
</script>
<style scoped>
.Header {
  font-family: RussianRail;
}
</style>
